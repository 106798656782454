<template>
  <div id="footerSis">
    <footer class="footerSis">
      <div class="container footerSis__container" align-h="center">
        <hr class="hrFooter" />
        <div class="col-md-12">
          <div class="row">
            <div class="col-md-4 footerLogoImage-column">
              <div class="footerLogoImage">
                <NuxtImg
                  class="footerLogoImage__sis"
                  loading="lazy"
                  src="/images/footer/logo.webp"
                  width="153px"
                  height="108px"
                  quality="100"
                  alt="Logo"
                  sizes="sm:153px md:153px lg:153px"
                />
              </div>
            </div>
            <div class="col-md-8">
              <div class="social_media">
                <div class="social_media__text">
                  <p style="margin: 0">
                    <span class="font-bold">
                      {{ $t('message.footer.text18[0]') }}
                    </span>
                    {{ $t('message.footer.text18[1]') }}
                  </p>
                </div>
                <a href="https://www.instagram.com/sisinnovtech/" target="_blank" aria-label="Instagram link">
                  <VIcon name="iconInsta" color="#FFFFFF" width="28px" height="29px" />
                </a>
                <a href="https://www.facebook.com/sisinnovtech" target="_blank" aria-label="Facebook link">
                  <VIcon name="iconFace" color="#FFFFFF" width="28px" height="29px" />
                </a>
                <a href="https://www.linkedin.com/company/sisinnovtech/" aria-label="Linkedin link" target="_blank">
                  <VIcon name="iconLinkedin" color="#FFFFFF" width="28px" height="29px" />
                </a>
              </div>
            </div>
          </div>

          <div class="row footerText">
            <div class="d-flex footerText__glassdoor">
              <a
                href="https://www.glassdoor.com.br/Vis%C3%A3o-geral/Trabalhar-na-SIS-Innov-and-Tech-EI_IE2491622.13,31.htm"
                target="_blank"
              >
                <NuxtImg
                  src="images/footer/glassdoor.png"
                  alt="adesivo glassdor"
                  loading="lazy"
                  width="370px"
                  height="40px"
                  fit="cover"
                  quality="100"
                  sizes="sm:370px md:370px lg:370px"
                />
              </a>
            </div>
          </div>

          <div class="row footerLinks">
            <div class="col-md-10 footerLinks__options">
              <NuxtLink :to="localePath('politica-de-privacidade')">{{ $t('message.footer.text2') }}</NuxtLink>
              <NuxtLink class="ml-20" :to="localePath('termos-e-condicoes-de-uso')">{{
                $t('message.footer.text3')
              }}</NuxtLink>
              <NuxtLink class="ml-20" :to="localePath('codigo-de-etica-e-conduta')">{{
                $t('message.footer.text4')
              }}</NuxtLink>
              <a
                class="ml-20"
                href="https://forms.office.com/pages/responsepage.aspx?id=jG4EXCQWbkirqLh8g87M6WdjNXlCqvhInYGLJKHxqS1UNEJNQ01MQ01ENzhDMjVGS1FPVUxETUJTMC4u&web=1&wdLOR=c1C41413A-3939-4EC2-9338-DC5A76E449E7"
                target="blank"
              >
                {{ $t('message.footer.text17') }}
              </a>
              <NuxtLink class="ml-20" :to="localePath('politica-de-cookies')">{{
                $t('message.footer.text19')
              }}</NuxtLink>
            </div>
            <div class="col-md-2 footerLinks__options--end">
              <a class="ml-20" href="https://canal.ouvidordigital.com.br/sisconsultoria" target="blank">
                {{ $t('message.footer.text15') }}
              </a>
            </div>
          </div>

          <div class="row">
            <div class="col d-flex justify-space-between footerTextEnd">
              <span>{{ $t('message.footer.text1') }}</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.blog__author__img__content {
  width: 40px !important;
  height: auto !important;
  border-radius: 50%;
  margin-right: 10px;
}

/* FOOTER */
.footerSis {
  background-color: #1f214a;
  color: #ffffff;
  font-size: 14px;
  display: flex;
  justify-content: center;
}

.footerSis strong {
  color: #00b4ff;
}

.footerSis ul {
  list-style: none;
  margin-left: -40px;
}

#footerSis {
  z-index: 99 !important;
  position: absolute;
  width: 100%;
}

.footerSis li {
  margin-top: 5px;
}

.hrFooter {
  height: 4px;
  background: #ffb500;
  opacity: 1;
  border: none;
}

.social_media {
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 30px;
}

.social_media__text {
  width: 210px;
  font: normal normal normal 16px/24px Montserrat;
  letter-spacing: 0px;
}

.social_media a {
  background: #4c4e71 0% 0% no-repeat padding-box;
  border-radius: 100%;
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.links-ext {
  display: flex;
  justify-content: left;
}

#footerSis a {
  cursor: pointer;
  color: #00b4ff;
}

.footerLogoImage-column {
  display: flex;
  align-items: flex-start;
}

.footerLogoImage {
  position: relative;
  display: flex;
  align-items: center;
  gap: 40px;
}

.footerLogoImage__gptw {
  width: 91px;
  height: 150px;
  margin-top: -5px;
}

.footerLogoImage__sis {
  width: 153px;
  height: 108px;
}

.footerText {
  margin-bottom: 25px !important;
  display: flex;
  justify-content: flex-end;
}

.footerText__glassdoor {
  flex-basis: content;
}

.footerText a {
  display: flex;
  justify-content: flex-end;
}

.footerText img {
  width: 360px;
  height: 40px;
}

.footerLinks {
  margin-bottom: 30px !important;
  display: flex;
  justify-content: space-between;
}

.footerLinks__options {
  gap: 30px;
  display: flex;
}

.footerLinks__options--end {
  display: flex;
  justify-content: flex-end;
  gap: 30px;
}

.footerTextEnd {
  margin-bottom: 20px;
  font: normal normal 12px/18px Montserrat;
  justify-content: center;
}

@media screen and (max-width: $mobile) {
  .links-ext {
    flex-direction: column;
    font-size: 13px;
    line-height: 45px;
    margin: 20px 10px;
    text-align: center;
  }

  .rights {
    margin-top: 15px;
  }

  .footerLogoImage {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;
  }

  .footerLogoImage-column {
    display: block;
  }

  .social_media {
    margin-left: 0;
    width: auto;
    height: auto;
    margin-bottom: 50px;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
  }

  .social_media__text {
    width: 100%;
    text-align: center;
  }

  .footerText {
    margin-bottom: 15px !important;
    padding: 0px;
    justify-content: center;
  }

  .footerText a {
    display: flex;
    justify-content: center;
  }

  .footerText img {
    width: 240px;
    height: auto;
  }

  .footerLogoImage {
    gap: 20px;
  }

  .footerLogoImage__sis {
    width: auto;
    height: auto;
  }

  .footerTextEnd {
    margin: 30px 0px;
    font: normal normal 12px/18px Montserrat;
    justify-content: center;
  }

  .footerLinks {
    margin-bottom: 0px !important;
    padding: 0px;
  }

  .footerLinks__options,
  .footerLinks__options--end {
    display: flex;
    justify-content: center;
    text-align: center;
    gap: 20px;
    flex-wrap: wrap;
    padding: 0;
    margin-top: 20px;
  }

  #footerSis a {
    cursor: pointer;
    color: #ccc;
  }
}
</style>
